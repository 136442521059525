<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">
            My posts

            <v-chip
               color="#3AC2D2"
               class="white--text"
               x-small
            >
              Beta
            </v-chip>
          </div>
          <span class="subtitle-1 light-grey">
            Here you can see specific posts from influencers you're collaborating with.

            <a href="https://intercom.help/Make-influence-com/en/articles/6248346-how-does-the-content-feature-work" target="_blank">
              Read more
            </a>
          </span>
        </div>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container class="p-0">
          <v-row wrap>
            <v-col md="4" sm="6" xs="12">
              <campaign-autocomplete
                 v-model="campaignId"
                 :return-object="false"
                 :display-draft="false"
              ></campaign-autocomplete>
            </v-col>
            <v-col md="4" sm="6" xs="12">
              <influencer-autocomplete
                 v-model="influencerId"
                 :return-object="false"
                 :disabled="campaignId === null"
              ></influencer-autocomplete>
            </v-col>
            <v-col md="4" sm="6" xs="12">
              <v-btn
                 class="mt-4"
                 color="primary"
                 type="submit"
                 :disabled="campaignId === null"
                 :loading="loading"
                 @click="search()"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <div v-if="posts.length === 0">

          <i v-if="loading">
            Searching...
          </i>

          <i v-else-if="total === null">
            Choose a campaign to see posts
          </i>

          <i v-else-if="campaignId && total !== null">
            No posts found. <router-link :to="{name: 'posts.attach'}">Save new posts</router-link> from influencers you're collaborating with
          </i>

          <img
             src="@/assets/no-content-business.svg"
             alt="No posts"
             class="default-img"
          />
        </div>

        <div v-if="posts.length > 0">
          <div class="text-right">
            {{ posts.length }} out of {{ total }}
          </div>
          <br/>

          <v-container class="p-0" grid-list-lg fluid>
            <v-row v-masonry>
              <v-col
                 v-for="item in posts"
                 :key="item.uuid"
                 xs="12" sm="6" md="4" lg="4"
              >
                <saved-media-card
                   :value="item"
                   @deleted="detachFromCampaign($event)"
                />
              </v-col>
            </v-row>

            <v-pagination
               v-if="posts.length"
               v-model="currentPage"
               class="mt-5"
               :length="lastPage"
               :total-visible="7"
               @input="_getInfluencerChannelPosts"
            ></v-pagination>
          </v-container>
  <!--      <div-->
  <!--         v-for="item in posts"-->
  <!--         :key="item.uuid"-->
  <!--         class="mb-5"-->
  <!--      >-->
  <!--        <saved-media-card-->
  <!--           :value="item"-->
  <!--        ></saved-media-card>-->
  <!--        <br/>-->
  <!--      </div>-->
  <!--      <v-pagination-->
  <!--         v-if="posts.length"-->
  <!--         v-model="page"-->
  <!--         class="my-2"-->
  <!--         :length="lastPage"-->
  <!--         :total-visible="7"-->
  <!--         @input="_getInfluencerChannelPosts($event)"-->
  <!--      ></v-pagination>-->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import SavedMediaCard from "../../cards/SavedMediaCard";
import CampaignAutocomplete from "../autocomplete/CampaignAutocomplete";
import InfluencerAutocomplete from "../autocomplete/InfluencerAutocomplete";

export default {
  components: {
    CampaignAutocomplete,
    InfluencerAutocomplete,
    SavedMediaCard,
  },
  mixins: [search_filters_url],
  data() {
    return {
      posts: [],
      selectedUuids: [],
      currentPage: 1,
      lastPage: null,
      loading: false,
      total: null,
      limit: 21,
      hasMore: true,
      campaignId: null,
      // campaignId: null,
      influencerId: null,
      business: null,
    };
  },
  computed: {
    ...mapGetters('subscriptions', ['hasPermission']),
  },
  watch: {
    filters: {
      handler: function () {
        // reset
        this.posts = [];
        this.currentPage = 1;
        this.loading = false;
        this.hasMore = true;
        // this.setUrlSearchFilters(this.filters);
        this._getInfluencerChannelPosts();
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions("business", ["getInfluencerChannelPosts", "detachInfluencerChannelPosts"]),
    search() {
      this.currentPage = 1;
      this._getInfluencerChannelPosts();
    },
    _getInfluencerChannelPosts(page = 1) {
      if (this.loading || !this.campaignId) {
        return;
      }

      this.currentPage = page;
      this.posts = [];
      this.loading = true;

      const params = {
        campaign_id: this.campaignId,
        influencer_id: this.influencerId,
        limit: this.limit,
        page: this.currentPage
      };

      this.getInfluencerChannelPosts(params).then(response => {
        this.posts = response.data.map(post => ({...post, selected: false}));

        // If no more items
        if (response.data.length !== params.limit) {
          this.hasMore = false;
        }

        this.loading     = false;
        this.currentPage = response.currentPage;
        this.lastPage    = response.lastPage;
        this.total       = response.total;
      });

    },
    filter() {
      this.filters = this.cloneDeep(this.filtersTmp);
      this.filterDialog = false;
    },
    detachFromCampaign(uuid) {
      let uuids = [uuid];

      let params = {
        campaign_id: this.campaignId,
        influencer_channel_post_ids: uuids
      };

      this.detachInfluencerChannelPosts(params).then(() => {
        this.setSnackSuccess('Removed');
        this.posts = this.posts.filter(item => item.uuid !== uuid);
        this.total--;
      }).catch(() => {
        this.setSnackError("Something went wrong");
      });
    },
    // This scroll event is a hack to force redraw the masonry grid
    // There's an issue with dynamic sizing, and loading of images
    // This causes the masonry to draw a size that's not loaded
    // Proper fix is to pre calculate sizes for all boxes
    scrollEvent() {
      if(this.posts.length === 0 || this.$vuetify.breakpoint.xsOnly) {
        return;
      }

      if(this.masonryTimeout !== null) {
        clearTimeout(this.masonryTimeout);
      }

      this.masonryTimeout = setTimeout(() => {
        this.$redrawVueMasonry();
      }, 350);
    }
  },
  updated() {
    // The masonry grid has to be rendered before drawing it
    // This will draw the grid multiple times to provide
    // Better user feedback so it doesn't seem empty
    for(let i = 1; i < 10; i++) {
      let timeout = i * 500;

      setTimeout(() => {
        this.$nextTick(() => {
          this.$redrawVueMasonry();
        });
      }, timeout);
    }
  },
  created() {
    // this.filters = this.getUrlSearchFilters();
    this._getInfluencerChannelPosts();
  },
  mounted() {
    document.addEventListener('scroll', this.scrollEvent);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollEvent);
  }
};
</script>

<style scoped></style>
