<template>
    <v-card class="_media-card">
      <div class="p-1" style="position: absolute; z-index: 8; width: 100%;">
        <div class="float-left">
          <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" top>
            <template v-slot:activator="{ on }">
              <v-chip
                 v-on="on"
                 color="secondary"
                 class="black--text"
                 x-small
              >
                <v-icon color="primary" x-small class="mr-1">
                  fab fa-instagram
                </v-icon>
                {{ isInstagramStory ? 'Story' : 'Feed' }}
              </v-chip>
            </template>
            <span>Instagram</span>
          </v-tooltip>
        </div>
        <div class="float-right">
          <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon small style="background: rgba(0,0,0, 0.2)" @click="showDialog = true;">
                <v-icon color="secondary" small>fal fa-chart-line</v-icon>
              </v-btn>
            </template>
            <span>Indsigt</span>
          </v-tooltip>
        </div>
      </div>

      <media-content
         :type="value.media_type"
         :files="value.files"
      ></media-content>

      <v-container class="pa-1">
        <div class="pl-1 pr-1">
          <div class="float-left">
            <div class="float-left mr-1">
              <v-avatar size="24" style="margin-top: 4px;">
                <v-img
                   :src="value.influencer_channel.influencer.user.avatar_url"
                   contain
                ></v-img>
              </v-avatar>
            </div>
            <div class="float-left ml-1">
              <div class="caption font-weight-bold">
                @{{ value.influencer_channel.username }}
              </div>
              <div class="caption" style="margin-top: -4px;">
                {{ value.influencer_channel.influencer.user.full_name }}
              </div>
            </div>
          </div>
          <div class="float-right caption">
            <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  {{ value.posted_at | humanize_datetime }}
                </span>
              </template>

              <span>{{ value.posted_at | friendly_date_format }}</span>
            </v-tooltip>
          </div>

          <div class="clearfix"></div>
        </div>
        <v-layout wrap>
          <v-flex xs6 class="pa-0">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ value.impressions | friendly_numbers(100000) }}
                </v-list-item-title>
                <v-list-item-subtitle>Impressions</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs6 class="pa-0">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title>
                  {{ value.reach | friendly_numbers(100000) }}
                </v-list-item-title>
                <v-list-item-subtitle>Reach</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12 class="pa-0">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title @click.stop="showDialog = true">
                  {{ visibleHashtag | truncate(truncateLength, '..') }}

                  <a v-if="invisibleHashTagCount > 0">
                    +{{ invisibleHashTagCount > 99 ? 99 : invisibleHashTagCount }}
                  </a>
                </v-list-item-title>
                <v-list-item-subtitle>Hashtags</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
        </v-layout>
      </v-container>

      <v-dialog v-model="showDialog" max-width="700px">
        <insights-media-card
          v-model="value"
          @closed="showDialog = false;"
          @deleted="$emit('deleted', $event); showDialog = false;"
        ></insights-media-card>
      </v-dialog>
    </v-card>
</template>

<script>
import humanize_datetime from "../../helpers/filters/humanize_datetime";
import truncate from "../../helpers/filters/truncate";
import friendly_date_format from "../../helpers/filters/friendly_date_format";
import friendly_numbers from "../../helpers/filters/friendly_numbers";
import MediaContent from '../media/MediaContent.vue';
import {MEDIA_PRODUCT_TYPE_STORY} from "../../helpers/types/media-product-type";
import InsightsMediaCard from "./InsightsMediaCard";

export default {
  filters: {humanize_datetime, truncate, friendly_date_format, friendly_numbers},
  components: {InsightsMediaCard, MediaContent},
  props: {
    value: {
      required: true,
      type: Object
    },
    selected: {
      required: false,
      type: Boolean,
      default: false
    },
    recentlyAttached: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isHovering: false,
    truncateLength: 30,
    showDialog: false,
    invisibleHashTagCount: 0,
    invisibleMentionsCount: 0,
  }),
  computed: {
    visibleMentionTag() {
      return this.calculateVisibleMentions();
    },
    visibleHashtag() {
      return this.calculateVisibleHashtags();
    },
    cardColor() {
      if (this.recentlyAttached) {
        return 'green accent-1';
      }

      if (this.selected) {
        return 'grey lighten-2';
      }

      return '';
    },
    showBadge() {
      return this.recentlyAttached || this.selected || this.isHovering;
    },
    badgeColor() {
      if (this.recentlyAttached) {
        return this.isHovering ? 'error' : 'success';
      } else if (this.selected) {
        return 'primary';
      }

      return 'grey lighten-2';
    },
    badgeIcon() {
      if (this.recentlyAttached) {
        return this.isHovering ? 'fas fa-heart-broken' : 'fas fa-heart';
      }

      if (this.selected) {
        return this.isHovering ? 'fas fa-heart-broken' : 'fal fa-heart';
      }

      return 'fal fa-heart';
    },
    badgeIconColor() {
      return this.selected || this.recentlyAttached ? 'white' : 'primary'
    },
    isInstagramStory() {
      return this.value.media_product_type === MEDIA_PRODUCT_TYPE_STORY;
    }
  },
  methods: {
    calculateVisibleMentions() {
      this.invisibleMentionsCount = 0;

      if (this.value.mention_tags.length === 0) {
        return '-';
      }

      let mentions = '';

      this.value.mention_tags.forEach((item) => {
        mentions += ' @' + item;

        // If hashtag is still visible
        if (mentions.trim().length > this.truncateLength) {
          this.invisibleMentionsCount++;
        }
      });

      return mentions.trim();
    },
    calculateVisibleHashtags() {
      this.invisibleHashTagCount = 0;

      if (this.value.hashtags.length === 0) {
        return '-';
      }

      let hashtag = '';

      this.value.hashtags.forEach((item) => {
        hashtag += ' #' + item;

        // If hashtag is still visible
        if (hashtag.trim().length > this.truncateLength) {
          this.invisibleHashTagCount++;
        }
      });

      return hashtag.trim();
    },
    handleClick() {
      this.$emit('change', true);
    }
  },
};
</script>

<style scoped>
.v-card {
  transition: opacity .125s ease-in-out;
}

.v-card .on-hover {
  opacity: 0.75;
}

.clearfix {
  clear:both;
}
</style>