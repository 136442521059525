<template>
  <v-card max-width="700px">
    <v-container fluid class="pa-0">
      <v-layout wrap>
        <div class="pt-0 pb-0" style="background: rgba(0,0,0,0.65); height: 534px; max-width: 300px;">
          <div class="p-1" style="position: absolute; z-index: 8; width: 68px;">
            <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
              <template v-slot:activator="{ on }">
                <v-chip
                   v-on="on"
                   color="secondary"
                   class="black--text"
                   x-small
                >
                  <v-icon color="primary" x-small class="mr-1">
                    fab fa-instagram
                  </v-icon>
                  {{ isInstagramStory ? 'Story' : 'Feed' }}
                </v-chip>
              </template>
              <span>Instagram</span>
            </v-tooltip>
          </div>

          <v-layout align-center justify-center fill-height>
            <media-content
               :type="value.media_type"
               :files="value.files"
               style="max-width: 300px;"
            ></media-content>
          </v-layout>
        </div>
        <div style="height: 534px; width:400px; overflow: auto" class="pa-2 pt-0">
          <div>
            <div class="float-left">
              <div class="float-left mr-1">
                <v-avatar size="48" style="margin-top: 4px;">
                  <v-img
                     :src="value.influencer_channel.influencer.user.avatar_url"
                     contain
                  ></v-img>
                </v-avatar>
              </div>
              <div class="float-left ml-1 mt-2">
                <div class="subtitle-2 font-weight-bold">
                  @{{ value.influencer_channel.username }}
                </div>
                <div class="caption" style="margin-top: -4px;">
                  {{ value.influencer_channel.influencer.user.full_name }}
                </div>
              </div>
            </div>
            <div class="float-right caption mt-1">
              {{ value.posted_at | friendly_date_format }}

              <v-icon
                  small
                  class="cursor-pointer ml-1"
                  style="margin-top: -2px;"
                  @click="$emit('closed', true)"
              >
                fas fa-times
              </v-icon>
            </div>
          </div>
          <div style="clear: both;"></div>

          <v-list-item v-if="value.campaigns.length > 0" dense class="pl-0 pr-0">
            <v-list-item-avatar>
              <v-icon>fal fa-bullhorn</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Campaign</v-list-item-title>
              <v-list-item-title>
                <router-link :to="{name: 'campaign', params: {id: value.campaigns[0].uuid}}">
                  {{ value.campaigns[0].name }}
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense class="pl-0 pr-0">
            <v-list-item-avatar>
              <v-icon>fal fa-bullseye</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Reach</v-list-item-title>
              <v-list-item-title>
                {{ value.reach | local_numbers }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense class="pl-0 pr-0">
            <v-list-item-avatar>
              <v-icon>fal fa-eye</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Impressions</v-list-item-title>
              <v-list-item-title>
                {{ value.impressions | local_numbers }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense v-if="isInstagramStory" class="pl-0 pr-0">
            <v-list-item-avatar>
              <v-icon>fal fa-door-open</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Exits</v-list-item-title>
              <v-list-item-title>
                {{ value.exits | local_numbers }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense v-if="isInstagramFeed" class="pl-0 pr-0">
            <v-list-item-avatar>
              <v-icon>fal fa-thumbs-up</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Likes</v-list-item-title>
              <v-list-item-title>{{ value.likes | local_numbers }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense v-if="isInstagramFeed" class="pl-0 pr-0">
            <v-list-item-avatar>
              <v-icon>fal fa-comments</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Comments</v-list-item-title>
              <v-list-item-title>{{ value.comments | local_numbers }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense v-if="isInstagramFeed" class="pl-0 pr-0">
            <v-list-item-avatar>
              <v-icon>fal fa-bookmark</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Saved</v-list-item-title>
              <v-list-item-title>
                {{ value.saves | local_numbers }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense v-if="isInstagramFeed" class="pl-0 pr-0">
            <v-list-item-avatar>
              <v-icon>fal fa-handshake</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Engagement</v-list-item-title>
              <v-list-item-title>
                {{ value.engagement | local_numbers }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div class="pl-2 pr-2">
            <div v-if="value.mention_tags.length > 0" class="mb-2">
              <div class="subtitle-2">Mentions</div>

              <a
                 v-for="mention in value.mention_tags"
                 :key="mention"
                 :href="'https://instagram.com/' + mention"
                 target="_blank"
                 class="caption mr-1"
              >@{{ mention }}
              </a>
            </div>

            <div v-if="value.hashtags.length > 0" class="mb-2">
              <div class="subtitle-2">Hashtags</div>

              <a
                 v-for="hashtag in value.hashtags"
                 :key="hashtag"
                 :href="'https://instagram.com/explore/tags/' + hashtag"
                 target="_blank"
                 class="caption mr-1"
              >#{{ hashtag }}
              </a>
            </div>

            <template v-if="isInstagramFeed">
              <div class="subtitle-2">Caption</div>

              <div class="caption">
                {{ value.caption }}
              </div>
            </template>

            <div class="mt-3">
              <v-btn v-if="! confirmDetach" text x-small @click="confirmDetach = !confirmDetach;">
                Delete
              </v-btn>

              <template v-if="confirmDetach">
                <v-alert type="warning">
                  This action can't be undone
                </v-alert>

                <v-btn text color="error" class="mr-2" @click="detachPost();">
                  Confirm
                </v-btn>
                <v-btn color="primary" @click="confirmDetach = !confirmDetach">
                  Cancel
                </v-btn>
              </template>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import humanize_datetime from "../../helpers/filters/humanize_datetime";
import friendly_date_format from "../../helpers/filters/friendly_date_format";
import local_numbers from "../../helpers/filters/local_numbers";
import truncate from "../../helpers/filters/truncate";
import MediaContent from '../media/MediaContent.vue';
import {MEDIA_PRODUCT_TYPE_FEED, MEDIA_PRODUCT_TYPE_STORY} from '../../helpers/types/media-product-type';

export default {
  filters: {humanize_datetime, friendly_date_format, local_numbers, truncate},
  components: {MediaContent},
  props: {
    value: {
      required: true,
      type: Object
    },
  },
  data: () => ({
    confirmDetach: false,
    detachLoading: false
  }),
  computed: {
    isInstagramFeed() {
      return this.value.media_product_type === MEDIA_PRODUCT_TYPE_FEED;
    },
    isInstagramStory() {
      return this.value.media_product_type === MEDIA_PRODUCT_TYPE_STORY;
    }
  },
  methods: {
    detachPost() {
      this.$emit('deleted', this.value.uuid);
    }
  },
  created() {
  }
};
</script>

<style scoped>
</style>